import React, {
    ReactElement, useEffect, useState,
} from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button, Checkbox, Container, Divider,
    Header,
    Image,
    Modal,
    Segment,
} from "semantic-ui-react";

import Banner from "../components/Banner";
import Footer from "../components/Footer";
import OrderProcess from "../components/home/OrderProcess";
import { isServiceError } from "../errors/types";
import { orderStore } from "../redux/slices/order";
import { RootState } from "../redux/store";
import { OrderService } from "../service/order";

/**
 * This component represents the home page from where a user can initialize a new order.
 *
 * @returns The home page
 */
export default function Home (): ReactElement {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const order = useSelector((state: RootState) => state.order);

    const [ confirmOpen, setConfirmOpen ] = useState<boolean>(false);
    const [ initOrderIsLoading, setInitOrderIsLoading ] = useState<boolean>(false);
    const [ agreedToPolicies, setAgreedToPolicies ] = useState<boolean>(false);

    useEffect(() => {
        if (order.id) {
            navigate(`/${ order.id }`);
        }
    }, []);

    /**
     * This function initializes a new order, sets the order id in the redux store and navigates to the order page.
     *
     * If the `order.id` is already set in the redux store, the function does nothing.
     *
     * If the no new order could be initialized, the function navigates to the `Error500` page.
     */
    async function initOrderAndNavigate (): Promise<void> {
        if (order.id) {
            return;
        }

        setInitOrderIsLoading(true);
        const response = await OrderService.init();
        if (isServiceError(response)) {
            if (response.statusCode === 429) {
                navigate("/429");
                return;
            }

            if (response.statusCode === 410) {
                navigate("/410");
                return;
            }

            // TODO: add toast notification instead of navigating and set serviceError.message as error
            // ! implement after migration to mantine
            navigate("/500");
            return;
        }

        const newOrder = response;
        dispatch(orderStore.setId(newOrder._id));
        dispatch(orderStore.setStep("DESIGNS"));
        dispatch(orderStore.clearError());
        navigate(`/${ newOrder._id }/models`);
    }

    /**
     * Closes the confirm modal and sets the aggree checkboxes to false.
     */
    function closeConfirmModal (): void {
        setConfirmOpen(false);
        setAgreedToPolicies(false);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>knopfdruck</title>
                <link rel="canonical"
                    href={`${ process.env.REACT_APP_HOST }`} />
                <meta name="robots"
                    content="index, follow" />
                <meta name="keywords"
                    content="3D-Druck, 3D-Druck Service, 3D-Druck Dienstleister, 3D-Druck Modell, 3D-Druck Modelle, 3D-Druck Online, 3D-Druck Druckservice, 3D-Druck Druckdienstleister, 3D-Druck Druckmodell, 3D-Druck Druckmodelle, 3D-Druck Druckservice Online, 3D-Druck Druckdienstleister Online, 3D-Druck Druckmodell Online, 3D-Druck Druckmodelle Online" />
                <meta property="og:url"
                    content={`${ process.env.REACT_APP_HOST }`} />
                <meta property="og:title"
                    content="knopfdruck | 3D Druck Service" />
                <meta property="og:site_name"
                    content="knopfdruck.eu" />
                <meta name="description"
                    property="og:description"
                    content="Sie haben eigene 3D-Modelle und haben jedoch derzeit keinen eigenen Drucker oder keine Kapazitäten? Einfach Modell hochladen, anpassen und per knopfdruck den Druckauftrag starten. Wir übernehmen den Rest. Ihre individuellen 3D-Objekte sind nur wenige Klicks entfernt!" />
                <meta property="og:image"
                    content={`${ process.env.REACT_APP_HOST }/img/logo_1_191.png`} />
                <meta property="og:image:width"
                    content="1200" />
                <meta property="og:image:height"
                    content="628" />
                <meta property="og:type"
                    content="website" />
            </Helmet>

            <Banner />

            <div
                style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
            >
                <Segment
                    inverted
                    textAlign="center"
                    padded="very"
                    vertical
                    style={{
                        paddingBottom: "60px",
                    }}>

                    <Image
                        src="/img/brand-logo.png"
                        verticalAlign="middle"
                        size="medium"
                    />

                    <Header as="h1"
                        style={{
                            fontSize: "4em",
                        }}
                    >
                        <span style={{ color: "#46bfc3" }}>k</span>nopfdruck
                    </Header>
                    <Header as="h2"
                        style={{
                            fontSize: "1.7em",
                            fontWeight: "normal",
                            marginTop: "1.5em",
                        }}
                    >
                        Drucken Sie Ihre 3D-Modelle auf knopfdruck!
                    </Header>
                    <Button
                        color="teal"
                        size="huge"
                        loading={initOrderIsLoading}
                        onClick={(): void => {setConfirmOpen(true);}}
                    >
                        Angebot einholen
                    </Button>
                </Segment>

                <OrderProcess/>

                <Divider/>

                <Segment
                    basic
                    textAlign="center"
                >
                    <Button
                        color="teal"
                        loading={initOrderIsLoading}
                        onClick={(): void => {setConfirmOpen(true);}}
                    >
                        Angebot einholen
                    </Button>
                </Segment>

                <Segment
                    basic
                    textAlign="center"
                    style={{ marginTop: "5em", marginBottom: "2em" }}
                >
                    <p  style={{ "fontSize": "12px" }}>
                            1) Die auswählbaren Optionen können je nach Modell nur eine Teilmenge der hier
                            aufgeführten enthalten. Die kompatiblen Optionen werden im Bestellprozess angezeigt.
                    </p>
                    <p  style={{ "fontSize": "12px" }}>
                                2) Bitte beachten Sie, dass zu der Versandzeit noch Produktionszeit hinzukommt.
                    </p>
                </Segment>

                {/* ? Spacing component to force footer to the bottom */}
                <div style={{ flex: 1 }}></div>
                <Footer/>
            </div>

            <Modal
                centered
                open={confirmOpen}
                onClose={closeConfirmModal}
            >
                <Modal.Header>Hinweise</Modal.Header>
                <Modal.Content>
                    <Container>
                        <p>
                        Wir weisen darauf hin, dass wir für die gedruckten Modelle keine Haftung übernehmen.
                        Wir behalten uns das Recht vor, Modelle abzulehnen, die gegen geltendes Recht verstoßen oder
                        die wir aus anderen Gründen nicht drucken möchten.
                        </p>

                        <Checkbox
                            label={
                                <label>
                                    Hiermit bestätige ich, dass ich die Hinweise, die <a
                                        href="/policies/terms-of-service"
                                        target="_blank">AGB</a> sowie die <a href="/policies/data-protection"
                                        target="_blank">Datenschutzbestimmungen</a> gelesen habe und diesen zustimme.
                                </label>
                            }
                            checked={agreedToPolicies}
                            onChange={(): void => {setAgreedToPolicies(!agreedToPolicies);} }
                        />
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Abbrechen"
                        onClick={closeConfirmModal}
                        secondary
                    />
                    <Button
                        primary
                        content="Weiter"
                        loading={initOrderIsLoading}
                        onClick={initOrderAndNavigate}
                        disabled={!agreedToPolicies}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
}
