import React, { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Step } from "semantic-ui-react";

import { orderStore } from "../redux/slices/order";
import { RootState } from "../redux/store";
import { allDesignsAreValid } from "../states/design";


/**
 * @returns Displays the current step of the order process
 */
export default function Steps (): ReactElement {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const order = useSelector((state: RootState) => state.order);
    const designs = useSelector((state: RootState) => state.designs);

    useEffect(() => {
        if (!order.id) {
            return;
        }

        if (order.step === "VARIANTS") {
            if (!enableVariantsStep()) {
                dispatch(orderStore.setStep("DESIGNS"));
                return;
            }
            navigate(`/${ order.id }/variants`);
            return;
        }

        if (order.step === "DESIGNS") {
            navigate(`/${ order.id }/models`);
            return;
        }
    }, [ order.step ]);


    /**
     * Checks whether the variants step should be enabled or not.
     *
     * @returns `true` if the variants step should be enabled, `false` otherwise.
     */
    function enableVariantsStep (): boolean {
        const designsArray = Object.values(designs);

        return allDesignsAreValid(designsArray);
    }


    return (
        <>
            <Container textAlign="center">
                <Step.Group size="small"
                    stackable="tablet">
                    <Step
                        active={order.step === "DESIGNS"}
                        link
                        onClick={(): void => {dispatch(orderStore.setStep("DESIGNS"));}}
                        icon="cube"
                        title="Modelle"
                        description="Laden Sie Ihre Dateien hoch"/>
                    <Step
                        active={order.step === "VARIANTS"}
                        disabled={!enableVariantsStep()}
                        link
                        onClick={(): void => {dispatch(orderStore.setStep("VARIANTS"));}}
                        icon="cubes"
                        title="Varianten"
                        description="Bestimmen Sie Druckspezifikationen"/>
                    <Step
                        active={order.step === "CHECKOUT"}
                        disabled={order.step !== "CHECKOUT"}
                        icon="truck"
                        title="Checkout"
                        description="Schließen Sie die Bestellung ab"
                    />
                </Step.Group>
            </Container>
        </>
    );
}
