import {
    CaseReducer, createSlice, PayloadAction,
} from "@reduxjs/toolkit";

import { OrderState } from "../../states/order";

const initialState: OrderState = {
    id: undefined,
    step: undefined,
    designsLoaded: false,
    variantsLoaded: false,
    error: null,
    showUserGuide: false,
    checkoutInProgress: false,
};

// set order id -----------------------
const setId: CaseReducer<OrderState, PayloadAction<string>> = (state, { payload: orderId }) => {
    return {
        ...state,
        id: orderId,
    };
};
// ------------------------------------

// handle error --------------------------
const setError: CaseReducer<OrderState, PayloadAction<string>> = (state, { payload: error }) => {
    return {
        ...state,
        error,
    };
};

const clearError: CaseReducer<OrderState> = (state) => {
    return {
        ...state,
        error: null,
    };
};
// ------------------------------------

const setStep: CaseReducer<OrderState, PayloadAction<OrderState["step"]>> = (state, { payload: step }) => {
    return {
        ...state,
        step,
    };
};

const clear: CaseReducer<OrderState> = () => {
    return{
        ...initialState,
    };
};

const setDesignsLoaded: CaseReducer<OrderState, PayloadAction<boolean>> = (state, { payload: designsLoaded }) => {
    return {
        ...state,
        designsLoaded,
    };
};


const setVariantsLoaded: CaseReducer<OrderState, PayloadAction<boolean>> = (state, { payload: variantsLoaded }) => {
    return {
        ...state,
        variantsLoaded,
    };
};

const toggleUserGuide: CaseReducer<OrderState> = (state) => {
    return {
        ...state,
        showUserGuide: !state.showUserGuide,
    };
};

const setCheckoutInProgress: CaseReducer<OrderState, PayloadAction<boolean>> = (state, { payload: checkoutInProgress }) => {
    return {
        ...state,
        checkoutInProgress,
    };
};

export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        setId,
        setError,
        setStep,
        clearError,
        clear,
        setDesignsLoaded,
        setVariantsLoaded,
        toggleUserGuide,
        setCheckoutInProgress,
    },
});

export const orderStore = orderSlice.actions;

export default orderSlice.reducer;
