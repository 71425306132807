import { AxiosError } from "axios";

import { ServiceError } from "./types";

export class VariantErrorHandler {
    static init (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;

        return {
            statusCode,
            message: "Eine neue Variante konnte nicht erstellt werden.",
        };
    }

    static getPrice (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message: string;

        switch (statusCode) {
        case 422:
            message = "Der Preis konnte nicht aktualisiert werden. Bitte wählen Sie andere Spezifikationen.";
            break;
        case 429:
            message = "Spezifikationen konnten nicht aktualisiert werden. Aufgrund von zu vielen Anfragen wurde diese abgelehnt.";
            break;
        default:
            message = "Der Preis konnte nicht aktualisiert werden.";
            break;
        }

        return { statusCode, message };
    }

    static delete (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;

        return {
            statusCode,
            message: "Die Variante konnte nicht gelöscht werden.",
        };
    }

    static updateSpecifications (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message: string;

        switch (statusCode) {
        case 404:
            message = "Die Variante konnte nicht gefunden werden.";
            break;
        case 422:
            message = "Die gewählten Spezifikationen scheinen ungültig zu sein. Bitte versuchen Sie eine andere Kombination.";
            break;
        default:
            message = "Die Spezifikationen der Variante konnten nicht aktualisiert werden.";
            break;
        }

        return { statusCode, message };
    }
}
