import React, { ReactElement } from "react";
import {
    Grid, Label, List,
} from "semantic-ui-react";


/**
 * This component displays a list of pros and cons for a single material to the user, when the user
 * hovers over the material's info icon in the `SpecificationEdit` component.
 *
 * @param pros - List of pro arguments for the Material
 * @param cons - List con arguments against the Material
 * @returns The MaterialProsAndCons component
 */
export default function MaterialProsAndCons (props: {
    pros: string[],
    cons: string[]
 }): ReactElement {
    return (
        <>
            <Grid columns={2}>
                <Grid.Column>
                    <Label
                        color="green"
                        content="Vorteile"
                    />
                    <List bulleted>
                        {props.pros.map((pro, index) => (
                            <List.Item
                                key={index}
                                content={pro}
                            />
                        ))}
                    </List>
                </Grid.Column>
                <Grid.Column>
                    <Label
                        color="red"
                        content="Nachteile"
                    />
                    <List bulleted>
                        {props.cons.map((con, index) => (
                            <List.Item
                                key={index}
                                content={con}
                            />
                        ))}
                    </List>
                </Grid.Column>
            </Grid>

        </>

    );
}
