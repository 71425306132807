import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import {
    Header, List, ListDescription, Segment,
} from "semantic-ui-react";

import { RootState } from "../../redux/store";
import { formatPrice } from "../../utils/price";
import Price from "../variants/Price";

/**
 * Displays a summary of designs and variants in the order.
 */
export default function OrderSummary (): ReactElement {

    const designs = useSelector((state: RootState) => state.designs);
    const variants = useSelector((state: RootState) => state.variants);

    return (
        <>
            <Segment basic>
                <Header as="h3">Bestellung</Header>

                <List
                    size="large"
                    divided
                >
                    {Object.values(designs).map((design) => (
                        <List.Item key={design.id}>
                            <List.Header style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}>{design.fileAlias}</List.Header>
                            {Object.values(variants).filter(variant => variant.designId === design.id).length > 0 ?
                                (<List.List>
                                    {
                                        Object.values(variants)
                                            .filter(variant => variant.designId === design.id)
                                            .map((variant) => (
                                                <List.Item
                                                    key={variant.id}
                                                >
                                                    <ListDescription
                                                        style={{ float: "left" }}
                                                    >
                                                        <Price
                                                            variantId={variant.id}
                                                            showQuantity={true}
                                                            totalPrice={false}
                                                        />
                                                    </ListDescription>
                                                    <List.Header
                                                        style={{
                                                            float: "right",
                                                        }}
                                                    >
                                                        <Price
                                                            variantId={variant.id}
                                                            showQuantity={false}
                                                            totalPrice={true}
                                                        />
                                                    </List.Header>
                                                    <br/>
                                                </List.Item>
                                            ))
                                    }
                                </List.List>)
                                :
                                (
                                    <></>
                                )
                            }
                        </List.Item>
                    ))}

                    <List.Item key="summary">
                        <List.Header style={{ float: "right" }}>
                            {formatPrice(Object.values(variants).reduce((sum, variant) => {
                                return sum + (variant.quantity * (variant.pricePerPiece || 0));
                            }, 0))}
                        </List.Header>
                        <List.Description>Zwischensumme</List.Description>
                        <br/>
                        <List.Description style={{ float: "right", "fontSize": "12px" }}>inkl. MwSt.</List.Description>
                        <br/>
                        <List.Description style={{ float: "right", "fontSize": "12px" }}>
                            <a
                                href="https://hirnstiff.de/policies/shipping-policy"
                                target="_blank"
                                rel="noreferrer"
                            >Versand</a> wird beim Checkout berechnet
                        </List.Description>
                    </List.Item>
                </List>
            </Segment>

        </>
    );
}
