import React, { ReactElement } from "react";
import {
    Grid,
    Header,
    List,
    Progress,
} from "semantic-ui-react";

import config from "../../config/config";


export default function DesignStep () : ReactElement {
    return <Grid.Row>

        <Grid.Column width={ 8 }>
            <Header as="h3">1. Laden Sie Ihre Modelle hoch</Header>
            <p>
                Im ersten Schritt können Sie die Modelle, die Sie drucken lassen möchten hochladen.
                Die Modelle können im { config.files.extensions.slice(0, config.files.extensions.length - 1).join(", ")}
                <span> </span> und {config.files.extensions[config.files.extensions.length - 1]} Format sein.
            </p>
        </Grid.Column>
        <Grid.Column width={ 8 }>
            <List>
                <List.Item>
                    <List.Icon name="file" />
                    <List.Content>sphere.stl</List.Content>
                    <Progress
                        style={{ marginTop: "0.5em" }}
                        percent={100}
                        indicating
                        size="small"
                        success
                    />
                </List.Item>
                <List.Item>
                    <List.Icon name="file" />
                    <List.Content>cone.obj</List.Content>
                    <Progress
                        style={{ marginTop: "0.5em" }}
                        percent={100}
                        indicating
                        size="small"
                        success
                    />
                </List.Item>
            </List>
        </Grid.Column>
    </Grid.Row>;
}
