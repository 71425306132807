import React, { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Message,
    Modal,
} from "semantic-ui-react";

import config from "../../config/config";
import { orderStore } from "../../redux/slices/order";
import { RootState } from "../../redux/store";
import { formatFileSize } from "../../utils/file";
import UserGuideStep from "./UserGuideStep";

/**
 * User Guide to give a brief overview of each step of the ordering process.
 *
 * @returns The user guide component
 */
export default function UserGuide (): ReactElement {
    const dispatch = useDispatch();
    const order = useSelector((state: RootState) => state.order);

    const [ activeStep, setActiveStep ] = useState<number>(0);

    const steps = [
        {
            header: "Modelle hochladen",
            imgSrc: "/img/user-guide-placeholder.png",
            content: <>
                <p>
                    Im erstem Schritt können Sie Ihre Modelle hochladen. Hierbei werden die Dateien auf ihre
                    Druckbarkeit geprüft. Sofern dies nicht der Fall ist, erhalten Sie eine entsprechende Meldung. Um
                    fortfahren zu können, bitten wir Sie, die nicht druckbaren Dateien zu entfernen.
                </p>
                <p>
                    Waren alle Tests erfolgreich, können Sie zum nächsten Schritt übergehen. Klicken Sie hierbei
                    lediglich auf <i>Weiter</i>.
                </p>
                <Message
                    header="Bitte beachten Sie die folgenden Hinweise:"
                    list={[
                        `Unterstütze Dateiformate: ${ config.files.extensions.join(", ") }`,
                        `Maximale Dateigröße: ${ formatFileSize(config.files.maxSize) }`,
                        "Druckgenauigkeit Z-Achse: 0,1 mm bis 0,2 mm.",
                        // TODO: Get max dimensions from backend via endpoint
                        "Maximales Druck-Volumen: 420 mm x 420 mm x 480 mm",
                        `Maximale Anzahl Modellen pro Bestellung: ${ config.order.maxDesignsperOrder }`,
                    ]}
                />
            </>,
        },
        {
            header: "Varianten konfigurieren",
            imgSrc: "/img/user-guide-placeholder.png",
            content: <>
                <p>
                    Sind Ihre Modelle erfolgreich hochgeladen, können Sie nun im zweiten Schritt Varianten für die
                    einzelnen Modelle konfigurieren.
                </p>
                <p>
                    Sagen Ihnen die voreingestellten Druckeigenschaften nicht zu, können Sie diese individuell anpassen.
                    Klicken Sie einfach auf <i>Bearbeiten</i> der jeweiligen Variante. Es erscheint ein Dialog zur
                    Anpassung der Parameter. Beispielsweise können Sie das Material und die Farbe ändern. Ebenfalls
                    können Sie auswählen, wie oft Sie eine Variante gedruckt haben möchten. Genauere Informationen
                    erhalten Sie innerhalb des Dialogs.
                </p>
                <p>
                    Haben Sie die gewünschten Druckeigenschaften ausgewählt, so klicken Sie auf <i>Übernehmen</i>.
                    Die Speicherung der Änderung kann einige Sekunden dauern. Möchten Sie ein Modell mit
                    unterschiedlichen Druckeigenschaften drucken, so fügen Sie weitere Varianten mittels des
                    <i>Variante hinzufügen</i> Button hinzu.
                </p>
                <p>
                    Sind alle Varianten erfolgreich konfiguriert, können Sie zum Checkout fortfahren. Klicken Sie
                    hierfür auf <i>Checkout</i>.
                </p>
                <Message
                    header="Bitte beachten Sie die folgenden Hinweise:"
                    list={[
                        "Druckoptionen können sich je nach Modell unterscheiden.",
                        `Maximale Anzahl Varianten pro Modell: ${ config.designs.maxVariantsPerDesign }`,
                        `Maximale Anzahl einer Variante: ${ config.variants.maxQuantity }`,
                    ]}
                />
            </>,
        },
        {
            header: "Bestellung abschließen",
            imgSrc: "/img/user-guide-placeholder.png",
            content: <>
                <p>
                    Im letzten Schritt können Sie Ihre Bestellung abschließen. Sie werden auf unsere Checkout-Seite
                    weitergeleitet. Sollten Sie Ihre Bestellung noch einmal ändern wollen, können Sie einfach
                    im Browser zurück navigieren. Es sollte ein Dialog erscheinen, der Sie darauf hinweist, dass die
                    Bestellung sich bereits im Checkout befindet. Klicken Sie zum Ändern auf <i>Bestellung öffnen</i>.
                    Laden Sie gegebenenfalls die Seite neu.
                </p>
                <Message
                    header="Bitte beachten Sie die folgenden Hinweise:"
                    list={[
                        `Wir speichern Ihre Bestellung nur auf bestimmte Zeit zwischen. Sollten Sie die Bestellung
                        für längere Zeit nicht abschließen, wird sie geschlossen.`,
                    ]}
                />
                <Message
                    color="teal"
                    header="Sie haben Fragen oder Anmerkungen?"
                    content={
                        <>
                            <p>
                        Gerne können Sie sich direkt an uns wenden. Wir versuchen Ihnen so gut wie möglich zu helfen.
                        Sie erreichen uns unter <a href="mailto:info@knopfdruck.eu">info@knopfdruck.eu</a>
                            </p>
                        </>
                    }
                />
            </>,
        },
    ];

    /**
     *
     */
    function nextStep (): void {
        if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        }
    }

    /**
     *
     */
    function previousStep (): void {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    return (
        <Modal
            closeIcon
            centered
            open={order.showUserGuide}
            onClose={(): void => {dispatch(orderStore.toggleUserGuide());}}
        >
            <Modal.Header>Schritt für Schritt Guide</Modal.Header>
            <Modal.Content>
                <UserGuideStep
                    header={steps[activeStep].header}
                    content={steps[activeStep].content}
                    imgSrc={steps[activeStep].imgSrc}
                />
            </Modal.Content>
            <Modal.Actions>
                { activeStep > 0 ?
                    <Button
                        color="black"
                        content="Zurück"
                        onClick={previousStep}
                    /> :
                    <></>
                }
                {
                    activeStep < steps.length - 1 ?
                        (
                            <Button
                                color="teal"
                                content="Weiter"
                                onClick={nextStep}
                            />) :
                        (
                            <Button
                                color="teal"
                                content="Los geht's!"
                                onClick={(): void => {dispatch(orderStore.toggleUserGuide());}}
                            />)
                }
            </Modal.Actions>
        </Modal>
    );
}
