import { getAlias } from "../utils/file";
import { CompatiblePrintOptions } from "./print";


export type DesignState = {
    /** The unique identifier of a design. Can be `undefined` if it has not been uploaded yet */
    id: string | undefined,
    /** Boolean to indicate whether the upload process is still running */
    isUploading: boolean,
    /** Upload progress [0-100] */
    uploadProgress: number | undefined,
    /**
     * Boolean to indicate whether the design is currently being
     * sliced for a new variant / new variant `PrintOptions`
     */
    isSlicing: boolean,
    /** Boolean to indicate whether the deletion of the design is currently running */
    isDeleting: boolean,
    /** The name of the file which is associated with the design */
    fileAlias: string,
    /** The size of the file in bytes */
    fileSize: number,
    /** The public Url to the design, which is used to render the design in the frontend. */
    publicUrl: string | undefined,
    /** The dimensions of the design */
    dimensions: Dimensions | undefined,
    /** The error message of the design, if applicable */
    error: string | null,
    /** The warning message of the design, if applicable */
    warning: string | null,
    /** The compatible print options for the design */
    compatiblePrintOptions: CompatiblePrintOptions | undefined,
}

export type Dimensions = {
    /** The length of the design in the X direction */
    sizeX: number,
    /** The length of the design in the Y direction */
    sizeY: number,
    /** The length of the design in the Z direction */
    sizeZ: number,
    /** Whether the associated design is a manifold or not */
    manifold: boolean,
    /** The unit of the design dimensions */
    unit: "mm",
}

/**
 * Based on the given file, the default state for a designs is returned.
 *
 * @param file - The file is used to determine the file alias and the file size.
 * @returns The default state for a design.
 */
export function getDefaultDesignState (file: File): DesignState {
    return {
        id: undefined,

        fileAlias: getAlias(file.name),
        fileSize: file.size,

        // ? design IsProcessed features
        isUploading: false,
        isSlicing: false,
        isDeleting: false,
        // ? --------------------------
        uploadProgress: undefined,

        // ? design IsReady features
        dimensions: undefined,
        publicUrl: undefined,
        compatiblePrintOptions: undefined,
        // ? --------------------------

        error: null,
        warning: null,
    };
}

/**
 * Takes dimension of a design and formats it into a readable string
 *
 * @param dimension - Dimension of a design
 * @returns Formatted string of dimension
 */
export function formatDimensions (dimension: Dimensions): string {
    return `${ dimension.sizeX }${ dimension.unit } x ${ dimension.sizeY }${ dimension.unit } x ${ dimension.sizeZ }${ dimension.unit }`;
}

/**
 * Checks if any of the dimensions are greater than zero.
 *
 * @param dimensions - Dimensions of a design
 * @returns If any of the dimensions are zero, returns `false`, else `true`
 */
export function dimensionsAreValid (dimensions: Dimensions | undefined): boolean {
    if (!dimensions) {
        return false;
    }

    if (dimensions.sizeX <= 0 || dimensions.sizeY <= 0 || dimensions.sizeZ <= 0) {
        return false;
    }

    if (!dimensions.manifold) {
        return false;
    }

    return true;
}

/**
 * Checks if the given array of designs are all valid. This is done by checking the following conditions:
 * - The array is not empty
 * - The error field of each design is falsy
 * - Each design is ready using the `designIsReady` function
 *
 * @param designs - Array of designs to check
 * @returns Whether or not all designs are valid
 */
export function allDesignsAreValid (designs: DesignState[]): boolean {
    if (Object.keys(designs).length === 0) {
        return false;
    }

    for (const design of designs) {
        if (design.error) {
            return false;
        }

        if (!designIsReady(design)) {
            return false;
        }
    }

    return true;
}

/**
 * Checks if the design is currently being processed by the backend.
 * This includes the `isUploading`, `isSlicing`, and `isDeleting` features of the design.
 *
 * @param design - The design to check
 * @returns Whether or not at least one of the features is set to truthy
 */
export function designIsProcessed (design: DesignState): boolean {
    if (design.isUploading) {
        return true;
    }

    if (design.isSlicing) {
        return true;
    }

    if (design.isDeleting) {
        return true;
    }

    return false;
}

/**
 * Checks if the design is ready for the variant step.
 * The `dimensions`, `publicUrl`, and `compatiblePrintOptions` fields must be truthy.
 *
 * @param design - The design to check
 * @returns Whether or not at least one of the features is set to truthy
 */
export function designIsReady (design: DesignState): boolean {
    if (!design.dimensions) {
        return false;
    }

    if (!design.publicUrl) {
        return false;
    }

    if (!design.compatiblePrintOptions) {
        return false;
    }

    return true;
}
