import React, { ReactElement } from "react";
import {
    Grid, Header, Segment,
} from "semantic-ui-react";

import CheckoutStep from "./CheckoutStep";
import DesignStep from "./DesignStep";
import VariantStep from "./VariantStep";

export default function OrderProcess (): ReactElement {
    return (
        <>
            <Segment
                vertical
                basic
            >
                <Grid
                    container
                    stackable
                    verticalAlign="middle"
                    style={{ marginTop: "1em", marginBottom: "2em" }}
                >
                    <Header as="h1">So funktioniert es</Header>
                </Grid>

                <Grid
                    container
                    stackable
                    verticalAlign="middle"
                    rows={3}
                    divided="vertically"
                >
                    <DesignStep/>
                    <VariantStep/>
                    <CheckoutStep/>
                </Grid>
            </Segment>
        </>
    );
}
