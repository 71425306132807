import React, { ReactElement } from "react";
import {
    Divider,
    Icon, List, Segment,
} from "semantic-ui-react";

/**
 * @returns The footer component
 */
export default function Footer (): ReactElement {
    return (
        <>
            <Segment
                basic
                fluid
                inverted
                textAlign="center"
                compact
                style={{ paddingTop: "30px" }}
            >
                <List
                    horizontal
                    inverted
                >
                    <List.Item
                        as="a"
                        href="https://www.hirnstiff.de/pages/about-us"
                        target="_blank"
                    >
                        Über uns
                    </List.Item>
                    <List.Item
                        as="a"
                        href="/policies/terms-of-service"
                        target="_blank"
                    >
                        AGBs
                    </List.Item>
                    <List.Item
                        as="a"
                        href="/policies/imprint"
                        target="_blank"
                    >
                        Impressum
                    </List.Item>
                    <List.Item
                        as="a"
                        href="/policies/data-protection"
                        target="_blank"
                    >
                        Datenschutzerklärung
                    </List.Item>
                </List>
                <br/>
                <List
                    horizontal
                    inverted
                >
                    <List.Item
                        as="a"
                        href="/policies/rights-of-withdrawal"
                        target="_blank"
                    >
                    Widerrufsbelehrung
                    </List.Item>
                    <List.Item
                        as="a"
                        href="/policies/shipping-conditions"
                        target="_blank"
                    >
                        Versandbedingungen
                    </List.Item>
                </List>
                <br/>
                <br/>
                <a
                    href="https://www.instagram.com/knopfdruck.eu.official/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                >
                    <Icon
                        name="instagram"
                        size="large"
                        link
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/knopfdruck-eu/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                >
                    <Icon
                        name="linkedin"
                        size="large"
                        link
                    />
                </a>
                <Divider/>
                <span style={{ fontSize: "12px" }}>© {new Date().getFullYear()}, Hirnstiff</span>
            </Segment>
        </>
    );
}
