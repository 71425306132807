import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Icon,
    List,
    Popup,
} from "semantic-ui-react";

import { isServiceError } from "../../errors/types";
import { designStore } from "../../redux/slices/design";
import { DesignService } from "../../service/design";
import { DesignState } from "../../states/design";
import { Analysis } from "../../states/print";

export default function DesignAnalysis (props: {design: DesignState}): React.ReactElement {
    const dispatch = useDispatch();
    const [ designAnalysis, setDesignAnalysis ] = useState<Analysis>({
        manifold: false,
        compatibleDimensions: false,
        compatiblePrintOptions: false,
        validNumberOfParts: false,
    });

    useEffect(() => {
        if (props.design.dimensions) {
            loadAnalysis();
        }
    }, [ props.design.dimensions, props.design.compatiblePrintOptions ]);

    async function loadAnalysis (): Promise<void> {
        if (props.design.id === undefined) {
            return;
        }

        const response = await DesignService.getAnalysis(props.design.id);

        if (isServiceError(response)) {
            dispatch(designStore.setError({ fileAlias: props.design.fileAlias, error: response.message }));
            return;
        }

        setDesignAnalysis(response);
    }

    function isLoading (): boolean {
        return !props.design.error && (!props.design.dimensions || !props.design.compatiblePrintOptions);
    }

    function hasError (): boolean {
        return !designAnalysis.manifold || !designAnalysis.compatibleDimensions || !designAnalysis.compatiblePrintOptions || !designAnalysis.validNumberOfParts;
    }

    return (
        <>
            {
                isLoading() ? (
                    <Icon
                        name="circle notch"
                        loading
                    />
                ) :

                    <Popup
                        wide="very"
                        trigger={
                            hasError() ?
                                <Icon
                                    name="warning sign"
                                    color="red"
                                /> :
                                <Icon
                                    name="check circle"
                                    color="green"
                                />
                        }
                        content={
                            <List>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.manifold ? "check circle" : "warning circle"}
                                        color={designAnalysis.manifold ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            Mannigfaltigkeit
                                        </List.Header>
                                        <List.Description>
                                            {designAnalysis.manifold ? "Das Modell ist mannigfaltig." : "Das Modell ist nicht mannigfaltig."}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.compatibleDimensions ? "check circle" : "warning circle"}
                                        color={designAnalysis.compatibleDimensions ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            Modellgröße
                                        </List.Header>
                                        <List.Description>
                                            {designAnalysis.compatibleDimensions ? "Die Größe des Modells ist druckbar." : "Das Modell ist zu groß für den Druck."}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.compatiblePrintOptions ? "check circle" : "warning circle"}
                                        color={designAnalysis.compatiblePrintOptions ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            Druckbarkeit
                                        </List.Header>
                                        <List.Description>
                                            {designAnalysis.compatiblePrintOptions ? "Es wurden kompatible Druckoptionen gefunden." : "Es konnten keine kompatiblen Druckoptionen gefunden werden."}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon
                                        name={designAnalysis.validNumberOfParts ? "check circle" : "warning circle"}
                                        color={designAnalysis.validNumberOfParts ? "green" : "red"}
                                    />
                                    <List.Content>
                                        <List.Header>
                                            Valide Anzahl an Teilstücken
                                        </List.Header>
                                        <List.Description>
                                            {designAnalysis.validNumberOfParts ? "Die enthaltenen Teilstücke sind druckbar." : "Das Modell enthält zu viele Teilstücke."}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        }
                    />

            }

        </>
    );
}
