import React, { ReactElement, useState } from "react";
import {
    Accordion,
    Grid,
    Header,
    Icon,
    Label,
    List,
    SemanticCOLORS,
} from "semantic-ui-react";

/**
 * Component to render a simple model demo using `three`.
 *
 * @returns the variant demo component
 */
export default function VariantStep (): ReactElement {
    const [ accordionActiveStep, setAccordionActiveStep ] = useState<number | null>(null);

    function accordionClickHandler (index: number): void {
        if (accordionActiveStep === index) {
            setAccordionActiveStep(null);
        } else {
            setAccordionActiveStep(index);
        }
    }

    // TODO: get material & color information from backend endpoint
    const fdmMaterialList: {text: string}[] = [
        { text: "PLA" },
        { text: "PLA+" },
        { text: "ABS" },
        { text: "ASA" },
        { text: "PETG" },
        { text: "Nylon" },
        { text: "TPU" },
    ];

    const slaMaterialList: {text: string}[] = [
        { text: "Universelles Detail-Harz" },
        { text: "Robust-Harz Plus" },
        { text: "Flexi-Stark-Harz" },
    ];

    const fdmColorList: {labelColor: SemanticCOLORS | undefined, labelText: string, text: string}[] = [
        {
            text: "Verkehrsschwarz",
            labelColor: "black",
            labelText: "RAL 9017",
        },
        {
            text: "Verkehrsweiß",
            labelColor: undefined,
            labelText: "RAL 9016",
        },
        {
            text: "Signalweiß",
            labelColor: undefined,
            labelText: "RAL 9003",
        },
        {
            text: "Graualuminium",
            labelColor: "grey",
            labelText: "RAL 9007",
        },
        {
            text: "Eisengrau",
            labelColor: "grey",
            labelText: "RAL 7011",
        },
        {
            text: "Verkehrsrot",
            labelColor: "red",
            labelText: "RAL 3020",
        },
        {
            text: "Verkehrsgrün",
            labelColor: "green",
            labelText: "RAL 6024",
        },
        {
            text: "Ultramarinblau",
            labelColor: "blue",
            labelText: "RAL 5002",
        },
        {
            text: "Himmelblau",
            labelColor: "blue",
            labelText: "RAL 5015",
        },
        {
            text: "Reinorange",
            labelColor: "orange",
            labelText: "RAL 2004",
        },
    ];

    const slaColorList: {text: string}[] = [
        {
            text: "Schwarz",
        },
        {
            text: "Weiß",
        },
        {
            text: "Grau",
        },
    ];

    return (
        <Grid.Row
            reversed="computer tablet"
        >
            <Grid.Column width={ 8 }>
                <Header as="h3">2. Legen Sie die gewünschten Spezifikationen fest</Header>
                <p>
                    Für jedes Modell können Sie gewünschte Spezifikationen, wie zum Beispiel
                    das Material oder die Farbe festlegen.
                    Sie erhalten direkt einen Kostenvoranschlag für die jeweils von Ihnen gewählten Spezifikationen.
                    <sup>1)</sup>
                </p>
            </Grid.Column>

            <Grid.Column width={ 8 }>
                <Accordion
                    fluid
                    styled
                >
                    <Accordion.Title
                        active={ accordionActiveStep === 0 }
                        index={ 0 }
                        onClick={(): void => accordionClickHandler(0)}
                    >
                        <Icon name='dropdown' />
                        Material
                    </Accordion.Title>
                    <Accordion.Content
                        active={ accordionActiveStep === 0 }
                        index={ 0 }
                    >
                        Wir bieten eine Auswahl an verschiedenen Materialien an:
                        <br/> <br/>
                        <Grid
                            columns={2}
                            stackable
                        >
                            <Grid.Row>
                                <Grid.Column widht={8}>
                                    <Header as="h5">FDM</Header>
                                    <List bulleted>
                                        {
                                            fdmMaterialList.map((materialListItem) => (
                                                <ListMaterialItem
                                                    key={materialListItem.text}
                                                    text={materialListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                                <Grid.Column widht={8}>
                                    <Header as="h4">SLA</Header>
                                    <List bulleted>
                                        {
                                            slaMaterialList.map((materialListItem) => (
                                                <ListMaterialItem
                                                    key={materialListItem.text}
                                                    text={materialListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Content>

                    <Accordion.Title
                        active={ accordionActiveStep === 1 }
                        index={ 1 }
                        onClick={(): void => accordionClickHandler(1)}
                    >
                        <Icon name='dropdown' />
                        Farbe
                    </Accordion.Title>
                    <Accordion.Content
                        active={ accordionActiveStep === 1 }
                        index={ 1 }
                    >
                        Ebenfalls können Sie die Farbe Ihres Modells auswählen:
                        <br/> <br/>
                        <Grid
                            columns={2}
                            stackable
                        >
                            <Grid.Row>
                                <Grid.Column widht={8}>
                                    <Header as="h5">FDM</Header>
                                    <List bulleted>
                                        {
                                            fdmColorList.map((colorListItem) => (
                                                <ListColorItem
                                                    key={colorListItem.text}
                                                    labelColor={colorListItem.labelColor}
                                                    labelText={colorListItem.labelText}
                                                    text={colorListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                                <Grid.Column widht={8}>
                                    <Header as="h4">SLA</Header>
                                    <List bulleted>
                                        {
                                            slaColorList.map((colorListItem) => (
                                                <ListColorItem
                                                    key={colorListItem.text}
                                                    labelColor={undefined}
                                                    labelText={undefined}
                                                    text={colorListItem.text}
                                                />
                                            ))
                                        }
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Accordion.Content>

                    <Accordion.Title
                        active={ accordionActiveStep === 2 }
                        index={ 2 }
                        onClick={(): void => accordionClickHandler(2)}
                    >
                        <Icon name='dropdown' />
                        Infill
                    </Accordion.Title>
                    <Accordion.Content
                        active={ accordionActiveStep === 2 }
                        index={ 2 }
                    >
                        Wählen Sie mit wieviel Füllung Ihr Modell gedruckt werden soll.
                    </Accordion.Content>
                </Accordion>
            </Grid.Column>
        </Grid.Row>
    );
}


function ListColorItem (props: {labelColor: SemanticCOLORS | undefined, labelText: string | undefined, text: string}): ReactElement {
    return <List.Item>
        {props.labelText ?
            <Label
                color={props.labelColor}
                content={props.labelText}
                horizontal
            /> : null
        }
        {props.text}
    </List.Item>;
}

function ListMaterialItem (props: {text: string}): ReactElement {
    return <List.Item>
        {props.text}
    </List.Item>;
}
