import { Material, Procedure } from "../config/config";
import { Color } from "./colors";

/** Describes the currently selected `PrintOptions` for a certain variant. */
export type PrintOptionsState = {
    /** Which procedure the variant should be produced with */
    procedure: Procedure,

    /** The material to use */
    material: Material,

    /** The color id of the material */
    colorId: string,

    /** The infill percentage the design should be printed with (between 0 and 100) */
    infill: number,
}


/**
 * Struct to describe all compatible options for a certain design. This struct is used to display available
 * options in the `SpecificationsEdit` component.
 */
export type CompatiblePrintOptions = {
    // ? procedure name
    [key: string]: {
        // ? material name
        [key: string]: {
            /** The alias of the material type, which can be used to display the material to the user. */
            alias: string,
            /** The full name of the material */
            fullName: string,
            /** Additional information for the user to describe differences between materials. */
            prosAndCons: Array<Array<string>>,
            /** The available colors for a certain material. */
            colors: Color[],
        }
    }
}

export type Analysis = {
    manifold: boolean,
    compatibleDimensions: boolean;
    compatiblePrintOptions: boolean;
    validNumberOfParts: boolean;
}

/**
 * Retrieves the Material alias defined in by the given `printOptions`
 *
 * @param compatiblePrintOptions - All print options, which are compatible with the given design
 * @param printOptions - the options which contain the material to get the alias for
 */
export function getMaterialAlias (compatiblePrintOptions: CompatiblePrintOptions | undefined, printOptions: PrintOptionsState): string {
    if (!compatiblePrintOptions) {
        return "";
    }

    return compatiblePrintOptions[printOptions.procedure][printOptions.material].alias;
}

/**
 * Retrieves the color alias defined by the given `printOptions`
 *
 * @param compatiblePrintOptions - All print options, which are compatible with the given design
 * @param printOptions - the options which contain the color to get the alias for
 */
export function getColorAlias (compatiblePrintOptions: CompatiblePrintOptions | undefined, printOptions: PrintOptionsState): string {
    if (!compatiblePrintOptions) {
        return "";
    }

    for (const color of compatiblePrintOptions[printOptions.procedure][printOptions.material].colors) {
        if (color.id === printOptions.colorId) {
            return color.alias;
        }
    }

    return "";
}
