import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import {
    Item,
    Message,
} from "semantic-ui-react";

import { RootState } from "../../redux/store";
import Design from "./Design";

/**
 * @returns This component displays a list of files that are currently being uplaoded.
 */
export default function DesignList (): ReactElement {
    const designs = useSelector((state: RootState) => state.designs);

    if (Object.keys(designs).length === 0) {
        return (
            <>
                <Message
                    content="Sie haben noch keine Modelle hochgeladen. Bitte laden Sie mindestens ein Modell hoch, um fortfahren zu können."
                    icon="info circle"
                    info
                />
            </>
        );
    }

    return (
        <>
            <Item.Group
                divided
                relaxed
            >
                {Object.keys(designs).map((fileAlias) => {
                    const design = designs[fileAlias];

                    if (!design) {
                        return (<></>);
                    }

                    return (
                        <Design
                            key={design.fileAlias}
                            fileAlias={design.fileAlias}
                        />
                    );
                })}
            </Item.Group>
        </>
    );
}
