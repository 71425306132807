import React, { ReactElement } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import {
    Header,
    Icon,
    Image, Menu, Popup,
} from "semantic-ui-react";

import { orderStore } from "../redux/slices/order";
import { RootState } from "../redux/store";
import DesignPageContinueButton from "./side-menu/DesignPageContinueButton";
import VariantPageBackButton from "./side-menu/VariantPageBackButton";
import VariantPageCheckoutButton from "./side-menu/VariantPageCheckoutButton";
import UserGuide from "./user-guide/UserGuide";

/**
 * @returns The navbar component
 */
export default function Navbar (): ReactElement {
    const order = useSelector((state: RootState) => state.order);
    const dispatch = useDispatch();


    return (
        <>
            <Menu
                fixed="top"
                inverted
                borderless
                fluid
            >
                <Menu.Item
                    position="left"
                    fitted
                    style={{ padding: "15px" }}
                    header
                >
                    <Header as="a"
                        href="https://knopfdruck.eu/"
                        target="_blank"
                        style={{
                            fontSize: "2.5em",
                            color: "white",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Image
                                src="/img/brand-logo.png"
                                as="a"
                                href="https://knopfdruck.eu/"
                                target="_blank"
                                style={{
                                    width: "60px",
                                    ...!isMobile ? { marginRight: "20px" } : {  },
                                }}
                            />
                            {
                                isMobile ?
                                    <></>
                                    : <><span style={{ color: "#46bfc3" }}>k</span>nopfdruck</>
                            }
                        </div>
                    </Header>
                </Menu.Item>


                <Menu.Menu
                    position="right"
                    compact
                >
                    {order.step === "DESIGNS" ? (
                        <Menu.Item position="right">
                            <DesignPageContinueButton/>
                        </Menu.Item>) : (<></>)}
                    {order.step === "VARIANTS" ? (
                        <Menu.Item
                            position="right"
                        >
                            <VariantPageBackButton/>
                            <VariantPageCheckoutButton/>
                        </Menu.Item>
                    ) : (<></>)}

                    {
                        order?.id ? (
                            <Popup
                                trigger={
                                    <Menu.Item
                                        size="massive"
                                        link
                                        onClick={(): void => {dispatch(orderStore.toggleUserGuide());}}
                                    >
                                        <Icon
                                            name="help circle"
                                            size="large"
                                        />
                                    </Menu.Item>
                                }
                                content="Hilfe"
                                position="left center"
                            />
                        ) : <></>
                    }
                </Menu.Menu>
            </Menu>

            {
                order?.id ? <UserGuide/> : <></>
            }

            <div style={{ height: "60px" }}/>
        </>
    );
}
