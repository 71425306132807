import React
, { ReactElement }
    from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Container,Header,
} from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";


/**
 * This component is used as an error page for `500` errors.
 *
 * @returns The `500` error page
 */
export default function Error500 () : ReactElement {
    const navigate = useNavigate();

    return (
        <PageTemplate>
            <Container textAlign="center"
                style={{
                    marginTop: "60px",
                    paddingBottom: "60px",
                }}>
                <div>
                    <Header style={{ fontSize: "6em" }}
                        textAlign="center">
                        500
                    </Header>
                    <Header size="huge"
                        style={{ marginBottom: "2em" }}
                        textAlign="center">
                            Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.
                    </Header>
                    <Button
                        as="a"
                        onClick={(): void => {navigate("/");}}
                        color="teal"
                    >
                        Zurückkehren
                    </Button>
                </div>
            </Container>
        </PageTemplate>
    );
}
