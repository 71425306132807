import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";

import { orderStore } from "../../redux/slices/order";

export default function VariantPageBackButton (): ReactElement{
    const dispatch = useDispatch();

    /**
     * Handles the back button, when the client wants to switch from the variant page to the design page.
     */
    function onBack (): void {
        dispatch(orderStore.setStep("DESIGNS"));
    }

    return <Button
        onClick={onBack}
        secondary
    >
        Zurück
    </Button>;
}
