type Config = {
    // banner information to be displayed on the homepage
    banner: {
        text: string | null,
    },
    files: {
        maxSize: number,
        extensions: string[],
    },
    order: {
        maxDesignsperOrder: number,
    },
    designs: {
        defaultPreviewUrl: string,
        maxVariantsPerDesign: number
        simultaneousPartUploads: number,
    },
    variants: {
        maxQuantity: number,
    }
}

const config: Config = {
    banner: {
        text: "20 % Rabatt mit dem Code: knopfdruck20",
    },
    // configs for allowed files
    files: {
        maxSize: 150 * 1024 ** 2,             // max 150 MB
        // allowed file extension (add new ones in lowercase)
        // ! extension must include the dot
        extensions: [ ".stl", ".obj", ".step", ".stp", ".3mf", ".amf" ],
    },
    order: { maxDesignsperOrder: 5 },
    designs: {
        defaultPreviewUrl: "/img/brand_logo_black_with_name.svg",
        maxVariantsPerDesign: 5,
        simultaneousPartUploads: 5,
    },
    variants: { maxQuantity: 10 },
};

export type Procedure = "fdm" | "sla";
export type Material = "abs" | "asa" | "nylon" | "petg" | "pla" | "plaplus" | "tpu" | "resin-standard" | "resin-tough" | "resin-abs";

export default config;
