import React, {
    ReactElement, useEffect, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, DropdownItemProps, DropdownProps, Form,
    Grid,
    Header,
    Icon,
    InputOnChangeData,
    List,
    Message, Modal,
    Popup, Segment, TextArea,
    TextAreaProps,
} from "semantic-ui-react";

import config, { Material, Procedure } from "../../../config/config";
import { isServiceError } from "../../../errors/types";
import { designStore } from "../../../redux/slices/design";
import { variantStore } from "../../../redux/slices/variant";
import { RootState } from "../../../redux/store";
import { VariantService } from "../../../service/variant";
import { Color } from "../../../states/colors";
import { CompatiblePrintOptions } from "../../../states/print";
import { variantIsValid } from "../../../states/variant";
import Price from "../Price";
import MaterialProsAndCons from "./MaterialProsAndCons";


/**
 * @param variantId - The identifier of the variant to edit.
 * @returns Returns a modal to edit the specifications of a variant.
 */
export default function SpecificationsEdit (props: { variantId :string }): ReactElement {
    const dispatch = useDispatch();

    const variant = useSelector((state: RootState) => state.variants[props.variantId]);
    const design = useSelector((state: RootState) => {
        const variant = state.variants[props.variantId];

        if (!variant) {
            return undefined;
        }

        for (const designKey in state.designs) {
            if (state.designs[designKey].id === variant.designId) {
                return state.designs[designKey];
            }
        }
    });


    if (!design) {
        return (<></>);
    }

    if (!design.compatiblePrintOptions) {
        return (<></>);
    }

    if (!variant) {
        return (<></>);
    }


    const [ procedure, setProcedure ] = useState<Procedure>(variant.printOptions.procedure as Procedure);
    const [ colorId, setColorId ] = useState<string>(variant.printOptions.colorId);
    const [ material, setMaterial ] = useState<Material>(variant.printOptions.material as Material);
    const [ infill, setInfill ] = useState<number>(variant.printOptions.infill);

    const [ quantity, setQuantity ] = useState<number | null>(variant.quantity);
    const [ customerNote, setCustomerNote ] = useState<string>("");

    const [ specsChanged, setSpecsChanged ] = useState<boolean>(false);
    const [ saveInProgress, setSaveInProgress ] = useState<boolean>(false);
    const [ cancelInProgress, setCancelInProgress ] = useState<boolean>(false);


    /**
     * Initializes the state variables for the edit modal. They are set to the current values of the variant in
     * the redux store.
     */
    function startEdit (): void {
        setProcedure(variant.printOptions.procedure as Procedure);
        setMaterial(variant.printOptions.material as Material);
        setColorId(variant.printOptions.colorId);
        setInfill(variant.printOptions.infill);

        setQuantity(variant.quantity);
        setCustomerNote(variant.customerNote ?? "");
    }

    /**
     * Handles the process to save the edited variant.
     */
    async function saveEdit (): Promise<boolean> {
        if (!design) {
            return false;
        }

        if (quantity === null) {
            return false;
        }
        setSpecsChanged(false);

        setSaveInProgress(true);
        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: true }));
        dispatch(variantStore.setPrice({ variantId: variant.id, pricePerPiece: undefined }));

        const response = await VariantService.updateSpecifications(
            variant.id,
            {
                procedure,
                material,
                colorId,
                infill,
            },
            quantity,
            customerNote,
        );

        if (isServiceError(response)) {
            dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
        } else {
            dispatch(variantStore.setSpecifications({ variantId: variant.id, quantity, customerNote, printOptions: { procedure, material, colorId: colorId, infill } }));
            dispatch(variantStore.clearError(variant.id));
        }

        setSaveInProgress(false);
        dispatch(designStore.setIsSlicing({ fileAlias: design.fileAlias, isSlicing: false }));

        return !isServiceError(response);
    }

    async function saveEditAndClose (): Promise<void> {
        const success = await saveEdit();

        if (success) {
            dispatch(variantStore.toggleEditMode(variant.id));
        }
    }

    /**
     * Handles the process to cancel the edit of the variant. If the variant is currently in an error state,
     * the previous state is tried to be restored from the redux store. If successful, the error is cleared.
     *
     * If the variant is not in an error state, the edit mode is simply toggled off.
     */
    async function cancelEdit (): Promise<void> {
        setCancelInProgress(true);

        if (!variantIsValid(variant) && !design?.isSlicing) {
            const response = await VariantService.updateSpecifications(
                variant.id,
                {
                    procedure: variant.printOptions.procedure as Procedure,
                    material: variant.printOptions.material as Material,
                    colorId: variant.printOptions.colorId,
                    infill: variant.printOptions.infill,
                },
                variant.quantity,
                variant.customerNote,
            );

            if (isServiceError(response)) {
                dispatch(variantStore.setError({ variantId: variant.id, error: response.message }));
            }
            // else {
            // dispatch(variantStore.clearError(variant.id));
            // }
        }

        dispatch(variantStore.toggleEditMode(variant.id));
        setCancelInProgress(false);
    }

    function allowCancel (): boolean {
        return !cancelInProgress && !saveInProgress;
    }

    /**
     * Handles the change event of a procedure. If the procedure changes, the state variable for the `procedure`
     * is updated and the state variables for `material` and `color` are updated to the first compatible option.
     *
     * @param event -
     * @param data -
     */
    function changeProcedure (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        if (!design?.compatiblePrintOptions) {
            return;
        }

        const procedureExists = data.value as Procedure in design.compatiblePrintOptions;

        if (!procedureExists) {
            return;
        }

        setProcedure(data.value as Procedure);

        const materialOptions = getMaterialDropdownOptions(data.value as Procedure, design.compatiblePrintOptions);
        const Material = materialOptions[0].value as Material;
        setMaterial(Material);

        const colorOptions = getColorDropdownOptions(data.value as Procedure, Material, design.compatiblePrintOptions);
        const colorName = colorOptions[0].value as string;
        setColorId(colorName);

        setSpecsChanged(true);
    }

    /**
     * Handles the change event of a material. If the material changes, the state variable for the `material`
     * is updated and the state variable for `color` is updated to the first compatible option.
     *
     * @param event -
     * @param data -
     */
    function changeMaterial (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        if (!design?.compatiblePrintOptions) {
            return;
        }

        const materialExists = design?.compatiblePrintOptions?.[procedure]?.[data.value as Material];

        if (!materialExists) {
            return;
        }

        setMaterial(data.value as Material);

        const colorOptions = getColorDropdownOptions(procedure, data.value as Material, design.compatiblePrintOptions);
        const colorName = colorOptions[0].value as string;
        setColorId(colorName);

        setSpecsChanged(true);
    }

    /**
     * Handles the change event of a color. If the color changes, the state variable for the `color` is updated.
     *
     * @param event -
     * @param data -
     */
    function changeColor (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        const colorExists = design?.compatiblePrintOptions?.[procedure]?.[material]?.colors.find(
            (color: Color) => color.id === data.value,
        );

        if (!colorExists) {
            return;
        }

        setColorId(data.value as string);
        setSpecsChanged(true);
    }

    /**
     * Handle the change event of the quantity. THe following checks are performed:
     * - The new quantity is a number
     * - The new quantity is between 1 and 10
     * If all checks are succesfull the state variable for the `quantity` is updated.
     *
     * @param event -
     * @param data -
     */
    function changeQuantity (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData): void {
        const newQuantity = parseInt(data.value as string);

        if (isNaN(newQuantity)) {
            setQuantity(null);
            return;
        }

        if (newQuantity < 1) {
            setQuantity(1);
            return;
        }

        if (newQuantity > config.variants.maxQuantity) {
            setQuantity(config.variants.maxQuantity);
            return;
        }

        setQuantity(newQuantity);
        setSpecsChanged(true);
    }

    /**
     * Handles the change event of the infill. The following checks are performed:
     * - The new infill is a number
     * - The new infill is between 10 and 100
     * - The new infill is a multiple of 10
     * If all checks are succesfull the state variable for the `infill` is updated.
     *
     * @param event -
     * @param data -
     */
    function changeInfill (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
        const newInfill = parseInt(data.value as string);

        if (isNaN(newInfill)) {
            return;
        }

        if (newInfill < 10 || newInfill > 100) {
            return;
        }

        if (newInfill % 10 !== 0) {
            return;
        }

        setInfill(newInfill);
        setSpecsChanged(true);
    }

    /**
     * Handles the change event of the customer note. The state variable for the `customerNote` is updated.
     * The length of the note is limited to 500 characters.
     *
     * @param event -
     * @param data -
     */
    function changeCustomerNote (event: React.SyntheticEvent<HTMLElement>, data: TextAreaProps): void {
        const newNote = data.value as string;

        if (newNote.length > 500) {
            setCustomerNote(newNote.substring(0, 500));
            return;
        }

        setCustomerNote(newNote);
    }


    /**
     * Function takes in the compatible print options of a design, which was retrieved from the backend. It then
     * generates a list of dropdown options for the procedure based on given compatible options.
     *
     * @param compatibleOptions - The compatible print options of a design, generated by the backend.
     * @returns List of dropdown options for the procedure. If no compatible options are given, an empty list is returned.
     */
    function getProcedureDropdownOptions (compatibleOptions: CompatiblePrintOptions): DropdownItemProps[] {
        const options: DropdownItemProps[] = [];

        for (const procedure in compatibleOptions) {

            if (!procedure) {
                continue;
            }

            options.push({
                key: procedure,
                value: procedure,
                text: procedure.toUpperCase(),
            });
        }

        if (!("fdm" in compatibleOptions)) {
            options.push({
                key: "fdm",
                value: "fdm",
                text: "FDM",
                disabled: true,
                selected: false,
            });
        }

        if (!("sla" in compatibleOptions)) {
            options.push({
                key: "sla",
                value: "sla",
                text: "SLA",
                disabled: true,
                selected: false,
            });
        }

        return options;
    }

    /**
     * Function takes in the currently selected procedure for the variant and the compatible print options of a design.
     * It then generates a list of dropdown options for the material based on given arguments.
     *
     * @param currentProcedure - The currently selected procedure for the variant.
     * @param compatibleOptions - The compatible print options of a design, generated by the backend.
     * @returns List of dropdown options for the material. If no compatible options are given, or the current procedure
     * does not exist in the compatible options, an empty list is returned.
     */
    function getMaterialDropdownOptions (currentProcedure: Procedure, compatibleOptions: CompatiblePrintOptions): DropdownItemProps[] {
        const options: DropdownItemProps[] = [];

        if (!(currentProcedure in compatibleOptions)) {
            return [];
        }

        for (const materialType in compatibleOptions[currentProcedure]) {
            const materialDefinition = compatibleOptions[currentProcedure][materialType];

            options.push({
                key: materialType,
                value: materialType,
                text: materialDefinition.alias,
                description: materialDefinition.fullName,
                content: (
                    <>
                        <Popup
                            wide="very"
                            content={
                                <MaterialProsAndCons
                                    pros={materialDefinition.prosAndCons[0]}
                                    cons={materialDefinition.prosAndCons[1]}
                                />
                            }
                            trigger={<Icon name="info circle"
                                link/>}
                        />
                        <span>{materialDefinition.alias}</span>
                    </>
                ),
            });
        }

        return options;

    }


    /**
     * Takes in the currently selected procedure and material for the variant and the compatible print options of a design.
     * It then generates a list of dropdown options for the color based on given arguments.
     *
     * @param currentProcedure - The currently selected procedure for the variant.
     * @param currentMaterial - The currently seleected material for the variant.
     * @param compatibleOptions - The compatible print options of a design. generated by the backend.
     * @returns List of dropdown options for the color. If no compatible color can be found, based on `currentProcedure`,
     * `currentMaterial`, and `compatibleOptions`, an empty list is returned.
     */
    function getColorDropdownOptions (currentProcedure: Procedure, currentMaterial: string, compatibleOptions: CompatiblePrintOptions): DropdownItemProps[] {
        const options: DropdownItemProps[] = [];

        if (!(currentProcedure in compatibleOptions)) {
            return [];
        }

        if (!(currentMaterial in compatibleOptions[currentProcedure])) {
            return [];
        }

        const material = compatibleOptions[currentProcedure][currentMaterial];
        const sortedColors = [ ...material.colors ].sort((a, b) => a.ralCode - b.ralCode);

        const blackColors = sortedColors.filter((color) => color.id.includes("black"));
        const whiteColors = sortedColors.filter((color) => color.id.includes("white"));
        const otherColors = sortedColors.filter((color) => !color.id.includes("white") && !color.id.includes("black"));

        const colors = [ ...blackColors, ...whiteColors, ...otherColors ];

        for (const color of colors) {
            options.push({
                key: color.id,
                value: color.id,
                text: color.alias,
                label: color.ralCode ? { color: color.labelColor, content: `RAL ${ color.ralCode }`, horizontal: true } : null,
            });
        }

        return options;
    }


    useEffect(() => {
        setSpecsChanged(false);
    }, []);

    useEffect(() => {
        if (variant.editMode) {
            startEdit();

        }
    }, [ variant.editMode ]);

    return (
        <>
            <Modal
                size="large"
                open={variant.editMode}
                closeOnDimmerClick={false}
                closeOnEscape={true}
                onClose={cancelEdit}
                closeIcon={allowCancel()}
            >
                <Modal.Header style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}>Variante bearbeiten</Modal.Header>
                <Modal.Content>
                    <Grid
                        columns={2}
                        divided
                        stackable
                        doubling
                    >
                        <Grid.Column
                            width={12}
                        >
                            {
                                variant.error !== null
                                    ? <Message error>{variant.error}</Message>
                                    : <></>
                            }

                            <Form loading={saveInProgress}>
                                <Form.Group>
                                    <Form.Dropdown
                                        selection
                                        openOnFocus
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content="Das Fertigungsverfahren beschreibt die Art und Weise, wie das Druckobjekt hergestellt wird. Die Auswahl des Verfahrens hat einen großen Einfluss auf die Eigenschaften des Objekts."
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                        Fertigung
                                            </>
                                        }
                                        value={procedure}
                                        options={getProcedureDropdownOptions(design.compatiblePrintOptions)}
                                        onChange={changeProcedure}
                                        disabled={saveInProgress}
                                        width={8}
                                    />

                                    <Form.Dropdown
                                        selection
                                        openOnFocus
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content="Das Material beschreibt den Werkstoff, aus dem das Druckobjekt hergestellt wird. Die Auswahl des Materials hat einen großen Einfluss auf die Eigenschaften des Objekts."
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                        Material
                                            </>
                                        }
                                        value={material}
                                        options={getMaterialDropdownOptions(procedure, design.compatiblePrintOptions)}
                                        onChange={changeMaterial}
                                        disabled={saveInProgress}
                                        width={8}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Dropdown
                                        selection
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content="In welcher Farbe möchten Sie die Variante gedruckt haben? Der angegebene RAL-Code beschreibt die Farbe des jeweiligen
                                            Filaments. Bitte beachten Sie jedoch, dass aufgrund der Verarbeitung des Filaments die Farbe des finalen Druckobjekts abweichen kann."
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                        Farbe
                                            </>
                                        }
                                        value={colorId}
                                        openOnFocus
                                        options={getColorDropdownOptions(procedure, material, design.compatiblePrintOptions)}
                                        onChange={changeColor}
                                        disabled={saveInProgress}
                                        width={6}
                                    />

                                    <Form.Input
                                        type="number"
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content="Die Anzahl beschreibt, wie oft diese Variante mit den ausgewählten Spezifikationen hergestellt werden soll."
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                        Anzahl
                                            </>
                                        }
                                        value={quantity}
                                        error={quantity === null}
                                        min={1}
                                        max={config.variants.maxQuantity}
                                        name="amount"
                                        step={1}
                                        onChange={changeQuantity}
                                        disabled={saveInProgress}
                                        width={4}
                                    />

                                    <Form.Dropdown
                                        selection
                                        label={
                                            <>
                                                <Popup
                                                    wide="very"
                                                    content="Die Füllung beschreibt die Dichte des Materials im Inneren des Druckobjekts. Eine höhere Füllung führt zu weniger Hohlraum, benötigt aber auch mehr Material und Zeit."
                                                    trigger={<Icon name="info circle"
                                                        link/>}
                                                />
                                        Füllung
                                            </>
                                        }
                                        value={procedure === "sla" ? "" : infill}
                                        placeholder="nicht anwendbar"
                                        openOnFocus
                                        options={[
                                            { key: 10, text: "10 %", value: 10 },
                                            { key: 20, text: "20 %", value: 20 },
                                            { key: 30, text: "30 %", value: 30 },
                                            { key: 40, text: "40 %", value: 40 },
                                            { key: 50, text: "50 %", value: 50 },
                                            { key: 60, text: "60 %", value: 60 },
                                            { key: 70, text: "70 %", value: 70 },
                                            { key: 80, text: "80 %", value: 80 },
                                            { key: 90, text: "90 %", value: 90 },
                                            { key: 100, text: "100 %", value: 100 },
                                        ]}
                                        onChange={changeInfill}
                                        disabled={saveInProgress || procedure === "sla"}
                                        width={6}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <TextArea
                                        placeholder="Haben Sie noch Anmerkungen?"
                                        value={customerNote}
                                        onChange={changeCustomerNote}
                                        width={16}
                                        rows={5}
                                    />
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                        <Grid.Column
                            floated="right"
                            width={4}
                        >
                            <Segment basic>
                                <Header
                                    as="h3"
                                    style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}
                                >
                                    {design.fileAlias}
                                </Header>

                                <List
                                    size="large"
                                    divided
                                >
                                    <List.Item>
                                        <List.List>
                                            <List.Description
                                                style={{ float: "left" }}
                                            >
                                                <Price
                                                    variantId={variant.id}
                                                    totalPrice={false}
                                                    showQuantity={true}
                                                />
                                            </List.Description>
                                            <List.Header
                                                style={{ float: "right" }}
                                            >
                                                <Price
                                                    variantId={variant.id}
                                                    totalPrice={true}
                                                    showQuantity={false}
                                                />
                                            </List.Header>
                                        </List.List>
                                    </List.Item>
                                </List>
                                <Message
                                    warning
                                    content="Der Preis wird aktualisiert, sobald Sie die Änderungen speichern."
                                    hidden={!specsChanged}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="black"
                        content="Aktualisieren"
                        loading={saveInProgress}
                        disabled={cancelInProgress || saveInProgress || design.isSlicing || quantity === null}
                        onClick={saveEdit}
                    />
                    <Button
                        color="teal"
                        content="Übernehmen"
                        loading={saveInProgress}
                        disabled={cancelInProgress || saveInProgress || design.isSlicing || quantity === null}
                        onClick={saveEditAndClose}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
}
