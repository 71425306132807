import React, { ReactElement } from "react";
import { Container, Header } from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";

export default function ShippingConditions (): ReactElement {
    return <PageTemplate>
        <Container
            text
            style={{
                marginTop: "60px",
                paddingBottom: "60px",
            }}
        >
            <Header as="h1">Versand</Header>

            <p>
                Versandkosten inklusive gesetzlicher Mehrwertsteuer
            </p>
            <p>
                Wir versenden mit der Deutschen Post, DHL, DPD, Hermes, UPS und GLS
            </p>

            <Header as="h2">Lieferung im Inland (Deutschland)</Header>
            <p>
                Wir berechnen die Versandkosten pauschal mit 4,99 € pro Bestellung.<br/>
                Versandlaufzeit: 3-4 Tage
            </p>


            <Header as="h2">Lieferung innerhalb EU</Header>
            <p>
                (Belgien, Tschechien, Dänemark, Finnland, Frankreich, Irland, Italien, Niederlande, Polen, Portugal,
                Spanien, Schweden, Österreich)
            </p>
            <p>
                Wir berechnen die Versandkosten pauschal mit 10,99 € pro Bestellung.<br/>
                Versandlaufzeit: 2-8 Tage
            </p>

            <Header as="h3">
                Lieferungen nach Österreich
            </Header>
            <p>
                Wir berechnen die Versandkosten pauschal mit 6,99 € pro Bestellung.
            </p>

            <Header as="h2">Lieferung ins Ausland</Header>
            <p>
                (Australien, Kanada, Sonderverwaltungsregion Hongkong, Israel, Japan, Malaysia, Neuseeland, Norwegen,
                Singapur, Südkorea, Vereinigte Arabische Emirate, Schweiz, Vereinigtes Königreich)
            </p>
            <p>
                Wir berechnen die Versandkosten pauschal mit 18,49 € pro Bestellung.<br/>
                Versandlaufzeit: 1 – 5 Wochen
            </p>


            <Header as="h3">Lieferungen in die Schweiz</Header>
            <p>
                Für Lieferungen in die Schweiz empfehlen wir MeinEinkauf.ch zu nutzen. Informationen finden Sie unter
                MeinEinkauf.ch. Alle Zollformalitäten und die Lieferung zu Ihren werden gegen eine geringe Gebühr
                von MeinEinkauf.ch übernommen.
            </p>

            <br/>
            <p>
                <strong>Hinweis:</strong> Es können Zollgebühren anfallen.
            </p>
        </Container>
    </PageTemplate>;
}
