import { AxiosError } from "axios";

import { ServiceError } from "./types";

export class OrderErrorHandler {
    static init (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 429:
            message = "Momentan befinden wir uns leider an unserem Kapazitätslimit. Bitte versuchen Sie es später erneut.";
            break;
        default:
            message = "Ein Fehler ist beim Erstellen der Bestellung aufgetreten. Bitte versuchen Sie es später erneut.";
            break;
        }

        return { statusCode, message };
    }

    static get (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = "Die Bestellung konnte nicht gefunden werden. Bitte überprüfen Sie die Bestellnummer.";
            break;
        case 410:
            message = "Die Bestellung scheint abgelaufen zu sein. Bitte erstellen Sie eine neue Bestellung.";
            break;
        default:
            message = "Die Bestellung konnte nicht geladen werden. Bitte versuchen Sie es später erneut.";
            break;
        }

        return { statusCode, message };
    }

    static getDesigns (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = "Die Bestellung konnte nicht gefunden werden. Bitte überprüfen Sie die Bestellnummer.";
            break;
        default:
            message = "Etwas ist schief gelaufen. Die Bestellung konnte nicht geladen werden.";
            break;
        }

        return { statusCode, message };
    }

    static getVariants (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = "Die Bestellung konnte nicht gefunden werden. Bitte überprüfen Sie die Bestellnummer.";
            break;
        default:
            message = "Etwas ist schief gelaufen. Die Bestellung konnte nicht geladen werden.";
            break;
        }

        return { statusCode, message };
    }

    static cancel (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = "Die Bestellung konnte nicht gefunden werden. Bitte überprüfen Sie die Bestellnummer.";
            break;
        default:
            message = "Die Bestellung konnte nicht storniert werden. Bitte versuchen Sie es später erneut.";
            break;
        }

        return { statusCode, message };
    }

    static checkout (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 400:
            message = "Die Bestellung scheint nicht bereit für den Checkout zu sein. Bitte überprüfen Sie die Bestellung.";
            break;
        case 409:
            message = "Die Bestellung scheint nicht bereit für den Checkout zu sein. Bitte überprüfen Sie die Bestellung.";
            break;
        case 404:
            message = "Die Bestellung konnte nicht gefunden werden. Bitte überprüfen Sie die Bestellnummer.";
            break;
        default:
            message = "Ein Fehler ist aufgetreten. Die Bestellung konnte nicht für den Checkout vorbereitet werden.";
            break;
        }

        return { statusCode, message };
    }

    static reopen (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 404:
            message = "Die Bestellung konnte nicht gefunden werden. Bitte überprüfen Sie die Bestellnummer.";
            break;
        default:
            message = "Ein Fehler is aufgetreten. Die Bestellung konnte nicht wieder geöffnet werden.";
            break;
        }

        return { statusCode, message };
    }
}
