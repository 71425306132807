import React from "react";
import { useSelector } from "react-redux";
import {
    Accordion, Grid, Icon, List,
} from "semantic-ui-react";

import { RootState } from "../../../redux/store";
import { DesignState } from "../../../states/design";
import { getColorAlias, getMaterialAlias } from "../../../states/print";
import Price from "../Price";


/**
 * @param variantId - Unique id of the variant
 * @returns Component to list the currently selected specifications of a variant.
 */
export default function SpecificationsShow (props: { variantId: string }): React.ReactElement {
    const variant = useSelector((state: RootState) => state.variants[props.variantId]);
    const design: DesignState | undefined = useSelector((state: RootState) => {
        return Object.values(state.designs).find(design => design.id === variant.designId);
    });

    const [ customerNoteCollapsed, setCustomerNoteCollapsed ] = React.useState(true);

    if (!design) {
        return (<></>);
    }


    if (!variant) {
        return (<></>);
    }

    return (
        <>
            <Grid
                style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}
            >
                <Grid.Row columns={2}>
                    <Grid.Column
                        width={10}
                    >
                        <List>
                            <List.Item>
                                <List.Content>
                                    <List.Header>Spezifikationen</List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>Fertigung: {variant.printOptions.procedure.toUpperCase()}</List.Item>
                            <List.Item>Material: {getMaterialAlias(design.compatiblePrintOptions, variant.printOptions)}</List.Item>
                            <List.Item>Farbe: {getColorAlias(design.compatiblePrintOptions, variant.printOptions)}</List.Item>
                            { variant.printOptions.procedure === "sla" ? <></> :
                                <List.Item>Infill: {`${ variant.printOptions.infill } %`}</List.Item>
                            }
                            <List.Item>Anzahl: {variant.quantity}</List.Item>
                        </List>
                    </Grid.Column>

                    <Grid.Column
                        width={6}
                        textAlign="right"
                        floated="right"
                    >
                        <span
                            style={{ fontWeight: "bold" }}
                        >
                            <Price

                                variantId={variant.id}
                                showQuantity={false}
                                totalPrice={true}
                            />
                        </span>
                        <br/>
                        <Price
                            variantId={variant.id}
                            showQuantity={false}
                            totalPrice={false}
                        /> / Stk.
                    </Grid.Column>
                </Grid.Row>

                {variant.customerNote ?
                    <Grid.Row columns={1}>
                        <Grid.Column
                            style={{ wordWrap: "anywhere", overflowWrap: "anywhere" }}
                        >
                            <Accordion
                                fliud
                            >
                                <Accordion.Title
                                    index={0}
                                    active={!customerNoteCollapsed}
                                    onClick={(): void => setCustomerNoteCollapsed(!customerNoteCollapsed)}
                                >
                                    <Icon name='dropdown' />
                                    Anmerkung
                                </Accordion.Title>
                                <Accordion.Content
                                    active={!customerNoteCollapsed}
                                >
                                    <p>
                                        {variant.customerNote}
                                    </p>
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Column>
                    </Grid.Row> :
                    <></>
                }
            </Grid>
        </>
    );
}
