import React, { ReactElement } from "react";
import { Header, Segment } from "semantic-ui-react";

import config from "../config/config";

export default function Banner (): ReactElement {
    if (!config.banner.text) {
        return <></>;
    }

    return (
        <>
            <Segment
                basic
                vertical
                color="teal"
                inverted
                textAlign="center"
            >
                <Header as="h4">
                    {config.banner.text}
                </Header>
            </Segment>
        </>
    );
}
