import React
, { ReactElement }
    from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Container,Header,
} from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";



/**
 * This component is used as an error page for `429` errors.
 *
 * @returns The `429` error page
 */
export default function Error429 () : ReactElement {
    const navigate = useNavigate();

    return (
        <PageTemplate>
            <Container textAlign="center"
                style={{
                    marginTop: "60px",
                    paddingBottom: "60px",
                }}>
                <div>
                    <Header style={{ fontSize: "6em" }}
                        textAlign="center">
                        429
                    </Header>
                    <Header size="huge"
                        style={{ marginBottom: "2em" }}
                        textAlign="center"
                        content="Im Moment erhalten wir zu viele Anfragen. Bitte versuchen Sie es später erneut."
                    />
                    <Button
                        as="a"
                        onClick={(): void => {navigate("/");}}
                        color="teal"
                    >
                        Zurückkehren
                    </Button>
                </div>
            </Container>
        </PageTemplate>
    );
}
