import { AxiosError } from "axios";

import config from "../config/config";
import { formatFileSize } from "../utils/file";
import { ServiceError } from "./types";

export class DesignErrorHandler {
    static initUpload (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 422:
            message = "Der Dateiname ist ungültig.";
            break;
        case 413:
            message = `Die Datei ist zu groß. Bitte wählen Sie eine Datei kleiner als ${ formatFileSize(config.files.maxSize) }`;
            break;
        case 415:
            message = `Das Dateiformat ist ungültig. Bitte wählen Sie eine Datei mit dem folgenden Format: ${ config.files.extensions.join(", ") }`;
            break;
        default:
            message = "Der Upload der Datei konnte nicht initialisiert werden.";
            break;
        }

        return { statusCode, message };
    }

    static completeUpload (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = "Der Upload der Datei ist fehlgeschlagen.";
            break;
        }

        return { statusCode, message };
    }

    static getPartUploadPresignedUrls (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        const message = "Der Upload der Datei ist fehlgeschlagen.";

        return { statusCode, message };
    }

    static uploadPart (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = "Der Upload der Datei ist fehlgeschlagen.";
            break;
        }

        return { statusCode, message };

    }

    static getPublicUrl (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = "Die Datei Voransicht konnte nicht geladen werden.";
            break;
        }

        return { statusCode, message };
    }

    static getDimensions (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        case 422:
            message = "Die Datei scheint invalide zu sein, bitte prüfen Sie die Datei auf Fehler.";
            break;
        default:
            message = "Die Dimensionen des Modells konnten nicht geladen werden. Bitte versuchen Sie es später erneut.";
            break;
        }

        return { statusCode, message };
    }

    static getCompatiblePrintOptions (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = "Es gibt keine kompatiblen Druckoptionen für das Modell.";
            break;
        }

        return { statusCode, message };
    }

    static getAnalysis (error: AxiosError): ServiceError {
        const statusCode = error?.response?.status || 500;
        let message;

        switch (statusCode) {
        default:
            message = "Die Analyse des Modells konnte nicht geladen werden.";
            break;
        }

        return { statusCode, message };
    }
}
